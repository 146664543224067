:root {
    --color-grey1: #CDD2D6;
    --color-orange: #E23D01B2;
    --color-white: #FFFFFF;
    --color-black: #000000;

    --grey-gradient: linear-gradient(180deg, var(--color-grey1) 22.28%, var(--color-white) 67.4%);
    --box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

html {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    letter-spacing: 0.05em;
    font-family: 'Jost', serif;
    line-height: normal;

    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

body {
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: 'Jost', serif;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

/* Utilities ====================================== */

/* Font size */

.small {
    font-size: 12px;
    font-family: 'Jost', serif;
}

.medium {
    font-size: 18px;
    font-family: 'Jost', serif;
}

.big {
    font-size: 25px;
    font-family: 'Jost', serif;
}

/* Font weight */

.bold {
    font-weight: 600;
}

.regular {
    font-weight: 400;
}

/* Colors */

.black {
    color: var(--color-black);
}

.white {
    color: var(--color-white);
}

/* List */

.list {
    padding: 30px;
    margin: 0;
}

.list-style-none {
    list-style: none;
}

.item {
    margin-bottom: 10px;
}

.item::marker {
    font-size: 9px;
}

/* Other */

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.underline {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none;
}

.arrow {
    cursor: pointer;
}

.arrow.down {
    position: absolute;
    bottom: 5%;
}

.section {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    background: var(--color-white);
}

.absolute {
    position: absolute;
    bottom: 7%;
}

.bg-fixed {
    background-image: url("/public/img/img2.jpg");

    -webkit-background-attachment: fixed;
    -moz-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-attachment: fixed;

    -webkit-background-repeat: no-repeat;
    -moz-background-repeat: no-repeat;
    -o-background-repeat: no-repeat;
    background-repeat: no-repeat;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    width: 100%;
    height: 100%;
}

.text-container {
    display: grid;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 50px;
}

.text-container > * {
    margin-bottom: 20px;
}

.active {
    display: block;
}

.back {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 1;
    width: 50px;
    height: 50px;
}

.back > * {
    width: 100%;
    height: 100%;
    font-size: 50px;
    color: black;
}

/* Header ====================================== */

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    z-index: 5;
}

.menu-bars, .menu-close {
    display: none;
}

/* Menu */

.navbar {
    max-width: 800px;
    height: 100%;
    display: flex;
    z-index: 10;
    justify-content: center;
    align-items: center;
}

.menu-list {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(4, auto);
    list-style: none;
    width: fit-content;
    height: fit-content;
    align-items: center;
    margin: 0;
    padding: 0;
    z-index: 5;
    text-align: center;
    justify-content: center;
}

.menu-item {
    position: relative;
    text-align: center;
    marker: none;
    width: fit-content;
    height: fit-content;
    white-space: nowrap;
    z-index: 100;
    margin: 0 auto;
}

.menu-item a {
    cursor: pointer;
    text-decoration: none;
    height: fit-content;
    padding: 10px 20px;
}

.menu-item button {
    outline: none;
    border: none;
    width: fit-content;
    display: inline-block;
    padding: 0;
    z-index: 511;
    background: none;
}

.dropdown {
    visibility: hidden;
    position: absolute;
    list-style: none;
    width: fit-content;
    left: 0;
    top: 100px;
    padding: 0;
    margin: 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    text-align: left;
    z-index: 1111;
    opacity: 0;
    display: grid;
    grid-gap: 25px;
}

.dropdown.active {
    visibility: visible;
    left: 0;
    top: 50px;
    opacity: 1;
}

.dropdown-item a {
    color: var(--color-black) !important;
    padding: 20px;
}

#menu-btn {
    width: 25px;
    height: 15px;
    margin: 50px auto;
    display: none;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 20;
}

#menu-btn span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--color-black);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#menu-btn span:nth-child(1) {
    top: 0;
}

#menu-btn span:nth-child(2), #menu-btn span:nth-child(3) {
    top: 8px;
}

#menu-btn span:nth-child(4) {
    top: 16px;
}

#menu-btn.open span:nth-child(1) {
    top: 10px;
    width: 0;
    left: 50%;
}

#menu-btn.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#menu-btn.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#menu-btn.open span:nth-child(4) {
    top: 18px;
    width: 0;
    left: 50%;
}

/* Footer ====================================== */

.footer {
    width: 100%;
    height: 180px;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.line {
    width: 2px;
    height: 100%;
    background: var(--color-black);
}

.rights {
    gap: 10px;
    display: flex;
    align-items: center;
}

.number {
    display: flex;
    text-align: center;
    align-items: center;
}

.footer .menu-item a {
    padding: 10px;
}
.footer .dropdown {
    top: -75px;
}

.footer .dropdown.active {
    top: -55px;
    grid-gap: 11px;
}

/* Home page ==================================== */

.main {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: auto;
    align-items: center;
}

.container:nth-child(1) {
    z-index: 4;
}

.container:nth-child(2) {
    z-index: 3;
}

.container:nth-child(3) {
    z-index: 2;
}

.container:nth-child(4) {
    z-index: 1;
}

/* Hero */

.hero {
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-items: end;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 94.21%, rgba(0, 0, 0, 0.2) 100%), url("/public/img/img1.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 4;
}

.hero-img {
    object-fit: cover;
    width: 100%;
    height: calc(100% + 2px);
}

/* About */

.container.about {
    background: var(--color-grey1);
    grid-template-areas:
            "text image";
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 50%);
    align-items: center;
    padding: 0;
}

.container.about .text-container {
    grid-area: text;
    padding: 50px;
}

.container.about .image-container {
    background: var(--color-orange);
    grid-area: image;
    align-items: end;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    position: relative;
}

.container.about .image-container.active {
    border-radius: 48% 52% 49% 51% / 37% 32% 68% 63%;
    width: 100%;
    height: 55%;
    margin-right: 20px;
}

.image-container .image {
    height: 100%;
    width: 100%;
    aspect-ratio: 1/1;
    box-sizing: border-box;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    object-fit: contain;
    object-position: bottom;
    margin-left: auto;
}

.image-container .image.active {
    border-radius: 48% 52% 49% 51% / 37% 32% 68% 63%;
    aspect-ratio: 1/1;
    height: 100%;
}

.end {
    max-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.refBox {
    position: absolute;
    visibility: hidden;
    height: 20px;
    bottom: 40%;
}

/* Contact */

.bg-white {
    background: var(--color-white);
    width: 100%;
    height: 100%;
}

.bg-orange {
    background: var(--color-orange);
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.bg-orange .text-container > * {
    margin-bottom: 20px;
}

.bg-orange .text-container div:last-child {
    margin-bottom: 0;
}

.pricing-tile {
    width: 100%;
    height: 100%;
    background: var(--color-grey1);
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
}

.pricing-tile a:hover {
    color: var(--color-white);
}

/* About Page ==========================================*/

.container.main-about {
    background: var(--color-grey1);
    align-items: start;
    margin: 0;
}

.container.main-about .list,
.container.main-about .text-container {
    padding: 150px 50px 50px 60px;
    margin: 0;
}

/* Pricing Page ==========================================*/

.container.pricing {
    background-image: url("/public/img/img2.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    text-align: left;
}

/* Media ==========================================*/

/* Main */
@media screen and (max-width: 1250px) {
    /* Utilities ================================*/
    .section {
        aspect-ratio: unset;
    }

    .container {
        grid-template-columns: auto;
        grid-template-rows: repeat(2, 50%);
        min-height: 100vh;
    }

    /* Home page =================================*/
    /*Hero*/
    .hero {
        height: 100vh;
    }

    /*About*/
    .container.about .text-container .narrow {
        display: none;
    }

    /*Services*/
    .container.services {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container.services .text-container {
        width: 100%;
        height: fit-content;
    }

    .container.services .bg-fixed {
        display: none;
    }

    /* About page */
    .container.main-about {
        align-items: baseline;
        justify-content: center;
        grid-template-columns: auto;
        grid-template-rows: repeat(2, auto);
        padding: 0;
    }

    .container.main-about .list:nth-child(2) {
        padding: 150px 50px 50px 60px;
        margin: 0;
    }

    .container.main-about .list:nth-child(3) {
        padding: 0 50px 50px 50px;
        margin: 0;
    }

    .container.main-about .image {
        display: none;
    }

    /* Pricing page */
    .empty {
        display: none;
    }

    .container.pricing {
        grid-template-rows: auto;
    }

    .container.pricing ul {
        padding: 60px;
    }
}

/* Header and menu list */
@media screen and (max-width: 1000px) {
    #menu-btn {
        display: block;
    }

    .header .menu-list {
        display: none;
    }

    .dropdown {
        grid-gap: 25px;
        top: 50px;
        left: -130px;
    }

    .dropdown.active {
        left: -130px;
        top: 0;
    }

    .footer .dropdown {
        top: 50px;
        left: -100px;
    }

    .footer .dropdown.active {
        left: -100px;
        top: 0;
        grid-gap: 32px !important;
    }

    .footer {
        display: grid;
        grid-template-columns: auto;
        align-items: center;
        justify-content: center;
        height: auto;
    }

    .footer .menu-list {
        grid-template-columns: auto;
        grid-gap: 25px;
        padding: 0;
    }

    .footer .line {
        width: 200px;
        height: 2px !important;
        margin: 25px auto;
    }

    .footer .number {
        justify-content: center;
    }

    .footer .arrow.up {
        position: absolute;
        right: 10%;
    }

    .header .menu-list {
        grid-gap: 25px;
    }

    .header .menu-list {
        display: grid;
        grid-template-columns: auto;
        position: absolute;
        top: -300px;
        right: 0;
        width: 100%;
        height: fit-content;
        z-index: 12;
        background: var(--color-grey1);
        box-sizing: border-box;
        padding: 25px;

        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }

    .header .menu-list.active {
        top: 0;
    }

    .header .menu-list li:nth-child(odd) a {
        color: var(--color-orange);
    }
}

/* Home -> About */
@media screen and (max-width: 900px) {
    .container.about {
        margin: auto;
        grid-template-areas:"image" "text";
        grid-template-columns: auto;
        grid-template-rows: 55% 45%;
    }

    .container.about .text-container {
        padding-top: 0;
        margin-bottom: auto;
    }

    .container.about .image-container.active {
        width: 40%;
        margin: 0 20px 0 auto;
    }

    .container.about .image-container .image {
        width: 50%;
        margin-left: auto;
    }

    .container.about .image-container .image.active {
        width: 100%;
    }

    .container.about .end {
        align-items: end;
    }
}

@media screen and (max-width: 700px) {
    .container.about {
        margin: auto;
        align-items: center;
        grid-template-areas:"image" "text";
        grid-template-columns: auto;
        grid-template-rows: 45% 55%;
    }

    .text-container {
        padding: 40px;
    }

    .footer .arrow.up {
        right: 5% !important;
    }

    .dropdown {
        grid-gap: 29px !important;
    }

    /* Fonts */
    .small {
        font-size: 11px;
    }

    .medium {
        font-size: 13px;
    }

    .big {
        font-size: 20px;
    }
}








